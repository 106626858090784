import { useContext, useEffect } from "react"
import { useLocation } from 'react-router-dom';

import Sidebar, { links } from "containers/sidebar/index.js"

import { GlobalContext } from "context/global.js"

import "./style.scss"

export default function Navigation({ children }) {
  const { state, setState } = useContext(GlobalContext)

  const location = useLocation()

  const menuClicked = () => {
    setState(prevState => ({
      ...prevState,
      sidebarOpened: !prevState.sidebarOpened
    }))
  }

  useEffect(() => {
    const link = links.find(l => {
      if(l.exactLink) {
        return l.to === location.pathname
      }

      return location.pathname?.includes(l.to)
    })

    if(link?.name) {
      setState(prevState => ({
        ...prevState,
        pageTitle: link.name
      }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <Sidebar />
      <div className="navigationContainer">
        <div className="navigation">
          <p>
            {state.pageTitle}
          </p>
          <div onClick={menuClicked}>
            <img src="icons/menu.svg" alt="" />
          </div>
        </div>
        <div className="navigationChildren">
          {children}
        </div>
      </div>
    </>
  )
}
