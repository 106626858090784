import { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"

import Navigation from "containers/navigation/index.js"

import { getMe, updateMe, changePassword } from "api/user.js"

import notify from "utils/notify.js"

import Input from "components/input/index.js"
import Button from "components/button/index.js"

import "./style.scss"

function Profile() {
  const [payload, setPayload] = useState({})

  // fetch user
  useEffect(() => {
    getMe().then(data => {
      if(data?.me) {
        setPayload(data.me)
      }
    }).catch(console.log)
  }, [])

  // update password
  const updatePassword = () => {}

  const changePayload = (value, key) => {
    setPayload(prevState => ({
      ...prevState,
      [key]: value
    }))
  }
    
  const submitForm = async (e) => {
    e.preventDefault()

    try {
      const data = await updateMe(payload)

      if(data?.message === "Successfully updated") {
        notify(data.message, "success")
        return;
      }
    } catch(e) {}

    notify("Došlo je do greške", "error")
  }

  const changePW = async (e) => {
    e.preventDefault()

    if(!payload.oldPassword) {
      notify("Unesite staru sifru", "error")
      return;
    }

    if(!payload.newPassword) {
      notify("Unesite novu sifru", "error")
      return;
    }

    try {
      const data = await changePassword(payload)

      if(data?.message === "Successfully updated") {
        notify(data.message, "success")
        return;
      } else {
        if(data?.message) {
          notify(data.message, "error")
        }
      }
    } catch(e) {
      notify(e?.message || e?.data?.message || "Došlo je do greške", "error")
      return;
    }
  }

  return (
    <Navigation>
      <div className="profileContainer">
        <form onSubmit={submitForm}>
          <h3>Osnovni podaci</h3>
          <div className="loginInputs">
            <Input label="Email" type="email" value={payload.email} onChange={e => changePayload(e?.target?.value, "email")} />

            <Button type="submit" name="Sacuvaj" />
          </div>
        </form>

        <form onSubmit={changePW}>
          <h3>Sigurnost</h3>
          <div className="loginInputs">
            <Input label="Stara sifra" type="password" value={payload.oldPassword} onChange={e => changePayload(e?.target?.value, "oldPassword")} />
            <Input label="Nova sifra" type="password" value={payload.newPassword} onChange={e => changePayload(e?.target?.value, "newPassword")} />

            <Button type="submit" name="Promijeni sifru" />
          </div>
        </form>
      </div>
    </Navigation>
  )
}

export default Profile
