import customFetch from "hoc/fetch.js"

export async function getActionById(id) {
	try {
		const response = await customFetch(`/api/admin/get/action/by/id`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				id: id
			})
		})

		const data = await response.json()

		return data
	} catch(e) {}

	return {
		action: {}
	}
}

export async function createAction(payload) {
	try {
		const response = await customFetch(`/api/create/action`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(payload)
		})

		const data = await response.json()

		return data
	} catch(e) {}

	return {
		message: "Došlo je do greške"
	}
}

export async function editAction(payload, id) {
	try {
		const response = await customFetch(`/api/edit/action/${id}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(payload)
		})

		const data = await response.json()

		return data
	} catch(e) {}

	return {
		message: "Došlo je do greške"
	}
}

export async function deleteAction(id) {
	try {
		const response = await customFetch(`/api/delete/action/${id}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
		})

		const data = await response.json()

		return data
	} catch(e) {}

	return {
		message: "Došlo je do greške"
	}
}
