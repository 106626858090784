import { Routes, Route } from "react-router-dom";

import Home from "pages/home/index.js"
import AddAction from "pages/add-action/index.js"
import EditAction from "pages/edit-action/index.js"
import Login from "pages/login/index.js"
import Profile from "pages/profile/index.js"

import withAuth from "hoc/withAuth.js"

export default function AllRoutes() {
  const ProtectedHome = withAuth(Home)
  const ProtectedAddAction = withAuth(AddAction)
  const ProtectedEditAction = withAuth(EditAction)
  const ProtectedProfile = withAuth(Profile)

  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />

      <Route exact path="/dodaj/akciju" element={<ProtectedAddAction />} />
      <Route exact path="/izmijeni/akciju/:id" element={<ProtectedEditAction />} />
      <Route exact path="/profil" element={<ProtectedProfile />} />

      <Route path="*" element={<ProtectedHome />} />
    </Routes>
  )
}
