import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';

import { UserProvider } from "context/user.js"
import { GlobalProvider } from "context/global.js"

import apolloClient from "api/index.js"

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import './variables.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <GlobalProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </GlobalProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
