import { useState } from "react"
import { Link } from "react-router-dom"

import Button from "components/button/index.js"

import { deleteAction } from "api/actions.js"

import notify from "utils/notify.js"

import Modal from "components/modal/index.js"

import "./style.scss"

const statuses = {
  pending: "Na čekanju",
  active: "Aktivan",
  finished: "Završen",
  rejected: "Odbijen"
}

export default function ActionCard({ featuredPhoto, title, id, status }) {
  const [showModal, setShowModal] = useState(false)
  const [deleteActionId, setDeleteActionId] = useState()

  const remove = async () => {
    const data = await deleteAction(deleteActionId)

		if(data?.message === "Successfully deleted") {
      notify(data.message, "success")

      setTimeout(() => {
        window.location.reload()
      }, 500)
		} else {
      notify(data?.message || 'Došlo je do greške', "error")
		}
  }

  const removeAction = (id) => {
    setShowModal(true)

    setDeleteActionId(id)
  }

  return (
    <>
      {showModal ?
          <Modal title={"Da li ste sigurni da zelite izbrisati akciju?"} onConfirm={remove} onClose={() => setShowModal(false)} />
        : null
      }
      <div className="actionCardContainer">
        <div className={`status ${status}`}>{statuses[status]}</div>

        <img src={featuredPhoto} alt="" className="featuredPhoto" />

        <div className="content">
          <p>{title?.slice(0, 20)}{title?.length > 20 ? "..." : null}</p>
          <Link to={`/izmijeni/akciju/${id}`}>
            <Button name="Izmijeni" bgColor="#107dac" />
          </Link>

          <Button name="Izbriši" onClick={() => removeAction(id)} />
        </div>
      </div>
    </>
  )
}
