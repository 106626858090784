import Button from "components/button/index.js"

import "./style.scss"

export default function Modal({ label, title, description, onConfirm = () => {}, onClose = () => {} }) {
  return (
    <div className="modalContainer">
      <img src="/icons/close-gray.svg" alt="X" className="icon" onClick={onClose} />

      <div className="contentContainer">
        <h3>{title}</h3>

        {description ?
          <p>{description}</p>
        : null}

        <div className="actions">
          <Button name="Potvrdi" onClick={onConfirm} bgColor="#107dac" />
          <Button name="Odustani" className="bgColorRed" onClick={onClose} />
        </div>
      </div>
    </div>
  )
}
