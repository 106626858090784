import { useState, useEffect } from "react"

import Navigation from "containers/navigation/index.js"

import ActionCard from "components/action-card/index.js"

import customFetch from "hoc/fetch.js"

import "./style.scss"

function Home() {
  const [actions, setActions] = useState([])

  useEffect(() => {
    const fetchActions = async () => {
      try {
        const response = await customFetch("/api/all/actions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          }
        })

        const data = await response.json()

        if(data?.actions) {
          setActions(data.actions)
        }
      } catch(e) {}
    }

    fetchActions()
  }, [])

  return (
    <Navigation>
      <div className="actions">
        {actions.map(action => (
          <ActionCard key={action.id} featuredPhoto={action.featuredPhoto} title={action.actionName} id={action.id} status={action.status} />
        ))}
      </div>
    </Navigation>
  )
}

export default Home
