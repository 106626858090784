import { Link } from "react-router-dom";

import './style.scss'

export default function LicniPodaciAkcija({ handleInputChange, payload }) {
  return (
    <div className="licniPodaciAkcija">
      <h2>Podaci Akcije</h2>
      <div>
        <div className="container-akcija1">
          <div>
            <p className="inputInfo">
              NAZIV AKCIJE<span>*</span>
            </p>{" "}
            <input className="inputAkcija1" value={payload.actionName} onChange={e => handleInputChange(e?.target?.value, "actionName")}></input>
          </div>
          <div>
            <p className="inputInfo">
              ŽELJENA SREDSTVA<span>*</span>
            </p>{" "}
            <input
              value={payload.targetSum}
              placeholder="Upišite ciljanu sumu akcije "
              type="number"
              className="inputAkcija1"
							onChange={e => handleInputChange(e?.target?.value, "targetSum")}
            ></input>
          </div>
          <div className="inputSaObjasnjenjem">
            <p className="inputInfo">
              OBRAČUNATA SREDSTVA<span>*</span>
            </p>{" "}
            <input className="inputAkcija1" disabled value={payload.targetSumWithTax || 0}></input>
            <p className="objasnjenje">
              Iznos koji je naveden za prikupljanje uvećava se za 9% na ime
              troškova nastalih zbog nenaplaćenih donatorskih SMS poruka od
              strane operatera, bankarskih provizija, kursnih razlika.
            </p>
          </div>
        </div>
        <div className="container-akcija2">
          <div>
            <p className="inputInfo">
              OBLAST AKCIJE<span>*</span>
            </p>{" "}
            <input value={payload.actionDomain} className="inputAkcija2" onChange={e => handleInputChange(e?.target?.value, "actionDomain")}></input>
          </div>
          <div>
            <p className="inputInfo">
              DATUM POKRETANJA AKCIJE <span>*</span>
            </p>{" "}
            <input value={payload.startDate} className="inputAkcija2" type="date" onChange={e => handleInputChange(e?.target?.value, "startDate")}></input>
          </div>
          <div>
            <p className="inputInfo">
              DATUM ZAVRŠETKA AKCIJE <span>*</span>
            </p>{" "}
            <input value={payload.endDate} className="inputAkcija2" type="date" onChange={e => handleInputChange(e?.target?.value, "endDate")}></input>
          </div>
          <div>
            <p className="inputInfo">GRAD I DRŽAVA REALIZACIJE AKCIJE<span>*</span></p>{" "}
            <input value={payload.actionLocation} className="inputAkcija2" onChange={e => handleInputChange(e?.target?.value, "actionLocation")}></input>
          </div>
        </div>
        <div>
          <div className="container-akcija3">
						<div>
							<p className="inputInfo">
								OPIS AKCIJE<span>*</span>
							</p>{" "}
							<textarea value={payload.description} className="textareaAkcija" onChange={e => handleInputChange(e?.target?.value, "description")}></textarea>
						</div>
          </div>
        </div>

      </div>
    </div>
  );
}
