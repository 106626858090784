import { useState, useEffect } from "react";
import React, { useRef } from "react";
import { nanoid } from "nanoid"
import { useParams } from 'react-router-dom';

import UploadFileComponent from "components/upload-file-component"
import LicniPodaciAkcija from "components/licni-podaci-akcija"

import Navigation from "containers/navigation/index.js"

import notify from "utils/notify.js"

import { uploadFiles } from "api/upload.js"
import { editAction, getActionById } from "api/actions.js"

import "./style.scss"

const pokretacAkcijePodaci = {
	pravnoLice: {
		naslov: "Organizacija",
		ime: "NAZIV ORGANIZACIJE",
		adresa: "SJEDIŠTE ORGANIZACIJE, GRAD I DRŽAVA",
		kontakt: "WEB STRANA",
	},
	fizickoLice: {
		naslov: "Lični podaci",
		ime: "IME I PREZIME",
		adresa: "ADRESA",
		kontakt: "KONTAKT TELEFON",
	}
}

const requiredFields = [
	"initiatorName",
	"initiatorAddress",
	"initiatorEmail",
	"initiatorBankAccount",
	"actionName",
	"targetSum",
	"actionDomain",
	"startDate",
	"endDate",
	"description",
	"photos",
]

const requiredFieldsPravnoLice = [
	"initiatorContactName",
	"initiatorContactPhone",
	"initiatorContactEmail",
]

const fieldsMapping = {
  "initiatorName": "Ime i Prezime",
  "initiatorAddress": "Adresa",
  "initiatorEmail": "E-mail",
  "initiatorBankAccount": "Žiro Račun",
  "actionName": "Naziv Akcije",
  "targetSum": "Željena sredstva",
  "actionDomain": "Oblast Akcije",
  "startDate": "Datum Pokretanja Akcije",
  "endDate": "Datum Završetka Akcije",
  "description": "Zašto Pokrećete Akciju",
  "termsAndCondition": "Uslovi Korišćenja",
  "identityConfirmationDocuments": "Dokument koji potvrđuje identitet",
  "photos": "Fotografije",
  "initiatorContactName": "Naziv Organizacije",
  "initiatorContactPhone": "Sjedište Organizacije",
  "initiatorContactEmail": "E-Mail",
}

export default function EditAction() {
	const [currentTab, setCurrentTab] = useState("fizickoLice")
	const [payload, setPayload] = useState({})

  let { id } = useParams();

  useEffect(() => {
    getActionById(id).then(response => {
      const { action } = response

      if(action) {
        setPayload(action)

        if(action.isLegalEntity) {
          setCurrentTab("pravnoLice")
        }
      }
    }).catch(console.log)
  }, [id])

	const handleInputChange = (value, key) => {
		setPayload(prevState => ({
			...prevState,
			[key]: value,
			...(key === "targetSum" ? { targetSumWithTax: parseFloat((+value + (+value * 0.09) )).toFixed(2) } : {}),
		}))
	}

  const handleButton1Click = () => {
		setCurrentTab("fizickoLice")
    setPayload(prevState => ({
      ...prevState,
      isLegalEntity: false,
    }))
  };

  const handleButton2Click = () => {
		setCurrentTab("pravnoLice")
    setPayload(prevState => ({
      ...prevState,
      isLegalEntity: true,
    }))
  };

	const createActionHandler = async () => {
		for(const key of requiredFields) {
			if(!payload[key]) {
        notify(`Popunite sva obavezna polja! ${fieldsMapping[key] || key}`, "warn")
				return;
			} else if(Array.isArray(payload[key]) && !payload[key].length) {
        notify(`Popunite sva obavezna polja! ${fieldsMapping[key] || key}`, "warn")
				return;
			}
		}

		if(payload.isLegalEntity) {
			for(const key of requiredFieldsPravnoLice) {
				if(!payload[key]) {
          notify(`Popunite sva obavezna polja! ${fieldsMapping[key] || key}`, "warn")
					return;
				}
			}
		}

		const customHash = nanoid(10)

		const uploadedPhotos = await uploadFiles(payload.newPhotos, customHash)

		const data = await editAction({
			...payload,
			targetSum: parseFloat(payload.targetSum),
      timeToImplementAfterFunding: payload.timeToImplementAfterFunding || 0,
			photos: payload.photos.concat(uploadedPhotos).join(','),
			identityConfirmationDocuments: payload.identityConfirmationDocuments?.join(',') || "",
			customHash,
		}, id)

		if(data?.message === "Successfully updated") {
      notify(data.message, "success")
      window.location.reload()
		} else {
      notify(data?.message || 'Došlo je do greške', "error")
		}
	}

  const removeImageFromGallery = (link, key) => {
    const filteredGallery = payload[key]?.filter(l => l !== link)

    setPayload(prevState => ({
      ...prevState,
      [key]: filteredGallery
    }))
  }

  return (
    <Navigation>
      <div className="editActionContainer">
        <div className="pokreniAkciju-container">
          <h2 className="naslovv">Status akcije</h2>
          <div className="buttoni statuses">
            <button
              className={payload.status === "pending" ? "button1" : "click1"}
              onClick={e => handleInputChange("pending", "status")}
            >
              Na čekanju
            </button>
            <button
              className={payload.status === "active" ? "button1" : "click1"}
              onClick={handleButton2Click}
              onClick={e => handleInputChange("active", "status")}
            >
              Aktivna
            </button>
            <button
              className={payload.status === "finished" ? "button1" : "click1"}
              onClick={e => handleInputChange("finished", "status")}
            >
              Završena
            </button>
            <button
              className={payload.status === "rejected" ? "button1" : "click1"}
              onClick={e => handleInputChange("rejected", "status")}
            >
              Odbijena
            </button>
          </div>

          <h2 className="naslovv">Pokretač akcije</h2>
          <div className="buttoni">
            <button
              className={payload.isLegalEntity ? "click1" : "button1"}
              onClick={handleButton1Click}
            >
              {" "}
              Fizičko lice
            </button>
            <button
              className={!payload.isLegalEntity ? "click2" : "button2"}
              onClick={handleButton2Click}
            >
              Pravno lice
            </button>
          </div>

          <div className="pravnoLice">
            <h2 className="sectionTitle">{pokretacAkcijePodaci[currentTab]?.naslov}</h2>
            <div className="organizacija">
              <div className="inputiDiv">
                <p className="inputInfo">
                  {pokretacAkcijePodaci[currentTab]?.ime}<span>*</span>
                </p>{" "}
                <input value={payload.initiatorName} className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorName")}></input>
              </div>
              <div className="inputiDiv">
                <p className="inputInfo">
                  {pokretacAkcijePodaci[currentTab]?.adresa}<span>*</span>
                </p>{" "}
                <input value={payload.initiatorAddress} className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorAddress")}></input>
              </div>
              <div className="inputiDiv">
                <p className="inputInfo">
                  EMAIL<span>*</span>
                </p>{" "}
                <input value={payload.initiatorEmail} className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorEmail")}></input>
              </div>
              <div className="inputiDiv">
                <p className="inputInfo">{pokretacAkcijePodaci[currentTab]?.kontakt}<span>*</span></p>{" "}
                <input value={payload.initiatorPhoneOrWebPage} className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorPhoneOrWebPage")}></input>
              </div>
              <div className="inputiDiv">
                <p className="inputInfo">FACEBOOK STRANA</p>{" "}
                <input value={payload.initiatorFacebook} className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorFacebook")}></input>
              </div>
              <div className="inputiDiv">
                <p className="inputInfo">INSTAGRAM STRANA</p>{" "}
                <input value={payload.initiatorInstagram} className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorInstagram")}></input>
              </div>
              <div
                className="inputiDiv inputOrganizacijaPodaciSedmiInput"
              >
                <p className="inputInfo">
                  ŽIRO RAČUN<span>*</span>
                </p>{" "}
                <input value={payload.initiatorBankAccount} className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorBankAccount")}></input>
              </div>
            </div>
            {
              payload.isLegalEntity ? 
                <>
                  <h2 className="sectionTitle">Kontakt osoba</h2>
                  <div className="organizacija">
                    <div className="inputiDiv">
                      <p className="inputInfo">
                        KONTAKT OSOBA<span>*</span>
                      </p>{" "}
                      <input value={payload.initiatorContactName} className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorContactName")}></input>
                    </div>
                    <div className="inputiDiv">
                      <p className="inputInfo">
                        KONTAKT TELEFON<span>*</span>
                      </p>{" "}
                      <input value={payload.initiatorContactPhone} className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorContactPhone")}></input>
                    </div>
                    <div className="inputiDiv">
                      <p className="inputInfo">
                        EMAIL<span>*</span>
                      </p>{" "}
                      <input value={payload.initiatorContactEmail} className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorContactEmail")}></input>
                    </div>
                  </div>
                </>
              : null
            }
          </div>

          <LicniPodaciAkcija handleInputChange={handleInputChange} payload={payload} />
          <UploadFileComponent handleInputChange={handleInputChange} />

          <div className="galerijaContainer">

            {payload?.photos?.length ?
              <div>
                <h2>Galerija</h2>
                <div className="galerija">
                  {payload?.photos?.map((i, idx) => (
                    <div key={idx} className="imageContainer">
                      <img src="/icons/close-red.svg" alt="" className="deleteImage" onClick={() => removeImageFromGallery(i, "photos")} />
                      <img src={i} alt="" className="slika" />
                    </div>
                  ))}
                </div>
              </div>
            : null}

            {payload?.identityConfirmationDocuments?.length ?
              <div>
                <h2>Identifikacioni dokumenti</h2>
                <div className="galerija">
                  {payload?.identityConfirmationDocuments?.map((i, idx) => (
                    <div key={idx} className="imageContainer">
                      <img src="/icons/close-red.svg" alt="" className="deleteImage" onClick={() => removeImageFromGallery(i, "identityConfirmationDocuments")} />
                      <img src={i} alt="" className="slika" />
                    </div>
                  ))}
                </div>
              </div>
            : null}

          </div>


          <div className="uploadBtnDiv">
            <button className="uploadBtn" onClick={createActionHandler}>Izmijeni akciju</button>
          </div>
        </div>
      </div>
    </Navigation>
  );
}
