import { useState, useEffect } from "react";
import React, { useRef } from "react";

import notify from "utils/notify.js"

import "./style.css";

export default function UploadFileComponent({ handleInputChange }) {
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const [uploadedFiles2, setUploadedFiles2] = useState([]);

  const handleDivClick = (fileInputRef) => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    handleInputChange(uploadedFiles2, "newPhotos");
  }, [uploadedFiles2]);

  const handleFileChange = (e, setUploadedFiles) => {
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 0) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
      ];
      const maxFileSize = 10 * 1024 * 1024; // 10MB

      const newFiles = Array.from(selectedFiles).filter((file) => {
        const isAllowedType = allowedTypes.includes(file.type);
        const isUnderMaxSize = file.size <= maxFileSize;

        return isAllowedType && isUnderMaxSize;
      });

      if (newFiles.length > 0) {
        setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
      } else {
        notify(
          "Molimo odaberite validne fajlove (slike, PDF, MSWord) do ukupne veličine 10MB.",
          "warn"
        );
      }
    }
  };

  const handleRemoveFile = (index, setUploadedFiles) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  return (
    <div className="container-upload">
      <h2>
        Fotografije <span>*</span>
      </h2>
      <div className="uploadComponent">
        <div>
          <div
            className="uploadFiles"
            onClick={() => handleDivClick(fileInputRef2)}
          >
            <img
              className="iconsUpload"
              src="/images/upload.png"
              alt="Upload icon"
            />

            <p className="identitet">Fotografije vezane za akciju</p>
            <div>
              <p className="opisUpload">Obavezno je priložiti barem 1 fajl.</p>
              <p className="opisUpload">
                Dozvoljeno je dodati najviše 20 fajlova.
              </p>
              <p className="opisUpload">
                Dozvoljeno je najviše 10.00Mb po fajlu.
              </p>
              <p className="opisUpload">
                Dozvoljeni tipovi fajlova su MSWord PDF i slike.
              </p>
            </div>
            <input
              type="file"
              ref={fileInputRef2}
              multiple
              onChange={(e) => handleFileChange(e, setUploadedFiles2)}
              style={{ display: "none" }}
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
            />
          </div>
          {uploadedFiles2.length > 0 && (
            <div className="dodaniFajlovi">
              <h3 className="h3Fajlovii">Dodani fajlovi:</h3>
              <ul>
                {uploadedFiles2.map((file, index) => (
                  <li className="liFajlovi" key={index}>
                    {file.name}
                    <button
                      onClick={() => handleRemoveFile(index, setUploadedFiles2)}
                    >
                      {" "}
                      X
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
