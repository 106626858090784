import { useState, createContext } from "react"

import { useQuery } from "@apollo/client"

import { GET_ME } from "api/queries/user.js"

const UserContext = createContext()

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    firstName: "Admin",
    lastName: "Admin",
    name: "Admin"
  })

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider }
