import customFetch from "hoc/fetch.js"

export async function uploadFiles(files, customHash) {
	try {
		if(!files?.length) {
			return []
		}

		const formData = new FormData()

		files.forEach((file, idx) => {
			formData.append(`file-${idx}`, file)
		})
		formData.append('customHash', customHash)

		const response = await customFetch(`/api/multi-upload`, {
			method: "POST",
			body: formData
		})

		const data = await response.json()

		if(data?.uploadedFiles) {
			return data.uploadedFiles
		}
	} catch(e) {
		console.log(e)
	}

	return []
}
