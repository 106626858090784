import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from '@apollo/client';

import { BACKEND_HOST, AUTH_TOKEN } from "constants/index.js"

const link = new HttpLink({
  uri: `${BACKEND_HOST}/graphql`
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` || null,
    }
  }));

  return forward(operation);
})

const client = new ApolloClient({
  link: concat(authMiddleware, link),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only"
    }
  }
});

export default client
