import { useState } from "react"
import { useQuery } from "@apollo/client"

import customFetch from "hoc/fetch.js"

import { AUTH_TOKEN } from "constants/index.js"

function withAuth(Component) {
  return function() {
    const [state, setState] = useState(false)

    ;(async () => {
      try {
        const token = localStorage.getItem(AUTH_TOKEN)

        const response = await customFetch("/api/validate/token", {
          method: "POST",
          headers: {
            "authorization": `Bearer ${token}`
          }
        })

        const data = await response.json()

        if(data.valid) {
          setState(true)
        } else {
          window.location.href = "/login"
        }
      } catch(e) {
          window.location.href = "/login"
      }
    })();

    return state ? <Component /> : null;
  }
}

export default withAuth
