import { useState, createContext } from "react"

const GlobalContext = createContext()

const GlobalProvider = ({ children }) => {
  const [state, setState] = useState({
    pageTitle: "Dashboard",
    sidebarOpened: false,
  })

  return (
    <GlobalContext.Provider
      value={{
        state,
        setState
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalContext, GlobalProvider }
