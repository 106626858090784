import BounceLoader from "react-spinners/BounceLoader"

import "./style.scss"

export default function Button({ name, loading = false, bgColor, ...args }) {
  return (
    <button {...args} className="button" style={{ ...(bgColor ? { backgroundColor: bgColor } : {}) }}>
      <span>
        <BounceLoader color="white" loading={loading} size={20} className="btnLoader" />
        {name}
      </span>
    </button>
  )
}
