import { BrowserRouter } from "react-router-dom";
import { ToastContainer, Slide } from 'react-toastify';

import Routes from "routes/index.js"

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
      <Routes />
    </BrowserRouter>
  );
}

export default App;
