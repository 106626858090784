import { useState, useContext } from "react"

import customFetch from "hoc/fetch.js"

import Input from "components/input/index.js"
import Button from "components/button/index.js"

import notify from "utils/notify.js"

import { UserContext } from "context/user.js"

import { AUTH_TOKEN } from "constants/index.js"

import "./style.scss"

export default function Login() {
  const [payload, setPayload] = useState({})

  const { setUser } = useContext(UserContext)

  const loginUser = async () => {
    try {
      const response = await customFetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: payload.email,
          password: payload.password,
        })
      })

      const data = await response.json()

      if(data?.token) {
        // User Logged in
        localStorage.setItem(AUTH_TOKEN, data.token)

        window.location.href = "/"
      } else {
        notify("Error occurred", "error")
      }
    } catch(e) {
      notify(e?.message || "Error occurred", "error")
    }
  }

  const changePayload = (value, type) => {
    setPayload(prevState => ({
      ...prevState,
      [type]: value
    }))
  }

  const submitForm = async (e) => {
    e.preventDefault()

    if(["email", "password"].some(key => !payload[key])) {
      notify("Please fill all required fields.", "error")
    
      return;
    }

    loginUser()
  }

  return (
    <div className="container">
      <form onSubmit={submitForm}>
        <h3>Admin Login</h3>

        <div className="loginInputs">
          <Input label="Email" type="email" required onChange={e => changePayload(e?.target?.value, "email")} />
          <Input label="Password" type="password" required onChange={e => changePayload(e?.target?.value, "password")} />

          <Button type="submit" name="Login" />
        </div>
      </form>
    </div>
  )
}
