import customFetch from "hoc/fetch.js"

export async function getMe() {
	try {
		const response = await customFetch(`/api/me`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
		})

		const data = await response.json()

		return data
	} catch(e) {}

	return {
		me: {}
	}
}

export async function updateMe(payload) {
	try {
		const response = await customFetch(`/api/update/me`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(payload)
		})

		const data = await response.json()

		return data
	} catch(e) {}

	return {
		message: "Došlo je do greške"
	}
}

export async function changePassword(payload) {
	try {
		const response = await customFetch(`/api/change/password`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(payload)
		})

		const data = await response.json()

		return data
	} catch(e) {}

	return {
		message: "Došlo je do greške"
	}
}
