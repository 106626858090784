import "./style.scss"

export default function Input({ label, ...args }) {
  return (
    <div className="inputContainer">
      <label>{label}</label>
      <input {...args} />
    </div>
  )
}
